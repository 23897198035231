import { NextSeo } from 'next-seo';
import { ReactNode } from 'react';
import { titleTemplate } from '@pages/_app';
import { Header } from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';

type LayoutProps = {
    children: ReactNode;
    title?: string;
};

const Layout = ({ children, title }: LayoutProps) => {
    return (
        <>
            <Header />
            <main>{children}</main>
            <Footer />
            <NextSeo
                title={title ? titleTemplate.replace('%s', title) : title}
                openGraph={{ title: title ? titleTemplate.replace('%s', title) : title }}
            />
        </>
    );
};

export { Layout };
