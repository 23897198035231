import { Input, StyledField, Title } from '@components/ContactForm/Field/Field.styles';
import { FieldHookConfig, useField } from 'formik';

type FieldProps = {
    label: string;
    placeholder?: string;
    required?: boolean;
} & FieldHookConfig<string>;

const Field = ({ label, placeholder, required = false, ...props }: FieldProps) => {
    const [field, meta] = useField(props);

    return (
        <StyledField>
            <Title required={required}>{label}</Title>
            <Input {...field} {...props} placeholder={placeholder} isError={meta.touched && meta.error !== undefined} />
        </StyledField>
    );
};

export { Field };
