import styled from 'styled-components';

export const StyledTextAreaField = styled.label`
    grid-column: 1 / span 3;
`;

export const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 10px;

    ${({ required }: { required?: boolean }) =>
        required &&
        `
        &::after {
            content: ' *';
            color: #FF001F;
        }
    `}
`;

export const TextArea = styled.textarea`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    width: 100%;
    height: 254px;
    background: #080808;
    border-radius: 15px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding: 25px 17px;
    resize: none;
    transition: box-shadow 250ms;

    ${({ isError }: { isError?: boolean }) => isError && `box-shadow: 0 0 10px 3px #ff0000;`}
`;
