import { Input, StyledCheckbox, Title } from '@components/ContactForm/Checkbox/Checkbox.styles';
import { FieldHookConfig, useField } from 'formik';

type CheckboxProps = {
    label: string;
    required?: boolean;
} & FieldHookConfig<boolean>;

const Checkbox = ({ label, required = false, ...props }: CheckboxProps) => {
    const [field, meta] = useField(props);

    return (
        <StyledCheckbox>
            <Input {...field} {...props} type="checkbox" isError={meta.touched && meta.error !== undefined} />
            <Title required={required}>{label}</Title>
        </StyledCheckbox>
    );
};

export { Checkbox };
