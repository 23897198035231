import styled from 'styled-components';

export const StyledCarouselItem = styled.li`
    min-width: 250px;
    opacity: 0.2;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    cursor: pointer;
    user-select: none;
    border-radius: 20px;

    ${({ isActive }: { isActive?: boolean }) => isActive && `opacity: 1;transform: scale(1.1);z-index: 20;`}
`;
