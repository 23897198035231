import {
    BackgroundImg,
    Center,
    Container,
    Creator,
    CreatorContainer,
    CreatorLink,
    Header,
    Link,
    RightContainer,
    SocialMedia,
    SocialMedias,
    StyledFooter,
    Tags,
    Title,
    Username
} from '@components/Footer/Footer.styles';
import { Wrapper } from '@components/Wrapper/Wrapper';
import useTranslation from 'next-translate/useTranslation';
import Img from 'next/image';

const Footer = () => {
    const { t } = useTranslation('footer');

    return (
        <StyledFooter>
            <BackgroundImg>
                <Img
                    src="/assets/footer.webp"
                    alt=""
                    layout="fill"
                    objectFit="cover"
                    objectPosition="top"
                    loading="lazy"
                />
            </BackgroundImg>
            <Wrapper>
                <Container>
                    <RightContainer data-aos="fade-left">
                        <Header>{t`title`}</Header>
                        <SocialMedias>
                            <SocialMedia>
                                <Title>Instagram</Title>
                                <Username>
                                    <Link
                                        href="https://www.instagram.com/anasofia_rn"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_social_instagram"
                                    >
                                        @anasofia_rn
                                    </Link>
                                </Username>
                            </SocialMedia>
                            <SocialMedia>
                                <Title>Tiktok</Title>
                                <Username>
                                    <Link
                                        href="https://www.tiktok.com/@anasofiarn12"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_social_tiktok"
                                    >
                                        @anasofiarn12
                                    </Link>
                                </Username>
                            </SocialMedia>
                            <SocialMedia>
                                <Title>E-mail</Title>
                                <Username>
                                    <Link href="mailto:daniel@reccly.pl" id="footer_email">
                                        daniel@reccly.pl
                                    </Link>
                                </Username>
                            </SocialMedia>
                        </SocialMedias>
                        <Center>
                            <Tags>{t`tags`}</Tags>
                        </Center>
                    </RightContainer>
                </Container>
                <CreatorContainer data-aos="fade-up" data-aos-offset="0">
                    <Creator>
                        @{' '}
                        <CreatorLink
                            href="https://reccly.com/"
                            target="_blank"
                            rel="noreferrer"
                            id="footer_link_creator"
                        >
                            <strong>reccly.com</strong>
                        </CreatorLink>{' '}
                        2022
                        <br />
                        ALL RIGHTS RESERVED
                    </Creator>
                </CreatorContainer>
            </Wrapper>
        </StyledFooter>
    );
};

export { Footer };
