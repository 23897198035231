import {
    Achievement,
    Container,
    Description,
    ImageContainer,
    LeftContainer,
    Logo,
    StyledAchievements,
    SubTitle,
    Title
} from './Achievements.styles';
import { Top, TopProps } from '@components/Top/Top';
import { TopList } from '@components/TopList/TopList';
import Img from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { memo } from 'react';

const Achievements = memo(() => {
    const { t } = useTranslation('achievements');
    const achievements = t(`topList`, {}, { returnObjects: true }) as Array<TopProps>;
    return (
        <StyledAchievements id="achievements">
            <Achievement>
                <LeftContainer data-aos="fade-right">
                    <Container>
                        <Title>{t`title`}</Title>
                        <SubTitle>{t`subTitle`}</SubTitle>
                        <Description>
                            <Trans
                                i18nKey="achievements:description"
                                components={{
                                    strong: <strong />
                                }}
                            />
                        </Description>
                        <TopList>
                            {achievements.map((achievement, i) => (
                                <Top key={i} {...achievement} />
                            ))}
                        </TopList>
                    </Container>
                </LeftContainer>
                <Logo>
                    <Img src="/assets/miss-egzotica-logo.webp" alt="" width={74} height={71} quality="100" />
                </Logo>
            </Achievement>
            <ImageContainer data-aos="fade-left">
                <Img
                    src="/assets/achievements.webp"
                    alt=""
                    layout="fill"
                    objectFit="cover"
                    objectPosition="top"
                    quality={100}
                />
            </ImageContainer>
        </StyledAchievements>
    );
});

Achievements.displayName = 'Achievements';

export { Achievements };
