import { StyledInfos } from '@components/Infos/Infos.style';
import { ReactNode } from 'react';

type InfosProps = {
    children?: ReactNode;
};

const Infos = ({ children }: InfosProps) => {
    return <StyledInfos>{children}</StyledInfos>;
};

export { Infos };
