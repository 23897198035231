import styled from 'styled-components';

export const StyledSocialSlider = styled.div`
    width: 505px;
    transform-origin: right;
    transform: rotate(-2deg);
    position: relative;

    @media screen and (max-width: 540px) {
        width: 300px;
    }
`;

export const SocialSliderWrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const SocialSliderContainer = styled.div`
    display: flex;
    grid-gap: 10px;
    transition: transform 500ms;
    position: relative;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    height: 22px;
    grid-gap: 7px;
`;

export const Page = styled.button`
    border: none;
    padding: 0;
    background-color: #d9d9d9;
    border-radius: 11px;
    height: 11px;
    width: 11px;
    overflow: hidden;
    display: block;
    cursor: pointer;
    -webkit-appearance: none;
    transition: background-color 250ms;

    ${({ isActive }: { isActive: boolean }) => isActive && `background-color: #319cff;`}
`;
