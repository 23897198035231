import styled from 'styled-components';

export const StyledTopList = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 80px;
    margin-top: 25px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        grid-gap: 30px;
    }
`;
