import styled from 'styled-components';
import { StyledContainer } from '@components/Wrapper/Wrapper.styles';

export const IntroSection = styled.section`
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    display: grid;
    align-items: center;
    position: relative;
`;

export const Background = styled.div`
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    opacity: 0.2;
    filter: grayscale(100%);

    @media screen and (max-width: 1100px) {
        width: 100%;
        background-position: center;
    }
`;

export const Container = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 40px;

    @media screen and (max-width: 1100px) {
        width: 100%;
        padding-right: 0;
    }
`;

export const Title = styled.h1`
    font-size: 40px;
    margin: 0;

    & > span {
        font-weight: 300;
        font-size: 30px;

        @media screen and (max-width: 1100px) {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 1100px) {
        font-size: 22px;
    }
`;

export const SubTitle = styled.h2`
    font-weight: 700;
    font-size: 50px;
    text-transform: uppercase;
    margin: 0;

    @media screen and (max-width: 1100px) {
        font-size: 30px;
    }
`;

export const Description = styled.p`
    font-weight: 300;
    font-size: 20px;
    margin: 19px 0 0;

    @media screen and (max-width: 1100px) {
        font-size: 16px;
    }
`;

export const Bottom = styled.div`
    width: 100%;
    position: absolute;
    bottom: 100px;
    left: 0;
    z-index: 100;

    @media screen and (max-width: 1440px) {
        width: calc(100% - 20px);
    }
`;

export const BottomContainer = styled(StyledContainer)`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const Socials = styled.div``;

export const StyledLink = styled.a`
    text-decoration: none;

    &:not(:last-child) {
        margin-right: 20px;
    }

    & > svg {
        transition: transform 250ms;
    }

    &:hover > svg {
        transform: scale(1.2);
    }
`;

export const SelectLanguage = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const Language = styled.a`
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    transition: transform 250ms;

    &:not(:last-child) {
        margin-right: 32px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 14px;
    }

    &:hover {
        transform: scale(1.2);
    }
`;

export const ScrollTo = styled.button`
    text-decoration: none;
    cursor: pointer;
    width: 52px;
    height: 52px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #393939;
    bottom: 76px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    z-index: 999;
    transition: background-color 250ms, transform 250ms;

    & > svg {
        fill: #fff;
        transition: fill 250ms;
    }

    &:hover {
        background: rgba(255, 255, 255, 1);
        transform: translateX(-50%) scale(1.1);

        & > svg {
            fill: #000;
        }
    }

    @media screen and (max-width: 1100px) {
        bottom: 20px;
    }
`;
