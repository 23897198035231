import { StyledTextAreaField, TextArea, Title } from '@components/ContactForm/TextAreaField/TextAreaField.styles';
import { FieldHookConfig, useField } from 'formik';

type TextAreaFieldProps = {
    label: string;
    placeholder?: string;
    required?: boolean;
} & FieldHookConfig<string>;

const TextAreaField = ({ label, placeholder, required, ...props }: TextAreaFieldProps) => {
    const [field, meta] = useField(props);

    return (
        <StyledTextAreaField>
            <Title required={required}>{label}</Title>
            <TextArea
                {...field}
                {...props}
                placeholder={placeholder}
                isError={meta.touched && meta.error !== undefined}
            ></TextArea>
        </StyledTextAreaField>
    );
};

export { TextAreaField };
