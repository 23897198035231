import {
    Page,
    Pagination,
    SocialSliderContainer,
    SocialSliderWrapper,
    StyledSocialSlider
} from '@components/SocialSlider/SocialSlider.styles';
import { Children, ReactNode, useEffect, useRef, useState } from 'react';

type SocialSliderProps = {
    children: ReactNode;
};

const SocialSlider = ({ children }: SocialSliderProps) => {
    const [page, setPage] = useState(0);
    const count = Children.count(children);
    const ref = useRef<HTMLDivElement>(null);
    const [widthDiv, setWidthDiv] = useState(0);
    const [gap, setGap] = useState(2);

    useEffect(() => {
        const nextPage = setTimeout(() => {
            if (page + 1 >= count) setPage(0);
            else setPage((prev) => prev + 1);
        }, 6 * 1000);

        return () => {
            clearInterval(nextPage);
        };
    }, [count, page]);

    useEffect(() => {
        const onResize = () => {
            if (ref.current) setWidthDiv(ref.current.getBoundingClientRect().width);
            if (window.innerWidth <= 1130) setGap(0);
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <StyledSocialSlider ref={ref}>
            <SocialSliderWrapper>
                <SocialSliderContainer style={{ transform: `translateX(-${widthDiv * page + page * gap}px)` }}>
                    {children}
                </SocialSliderContainer>
            </SocialSliderWrapper>
            <Pagination aria-hidden="true">
                {new Array(count).fill(0).map((_, i) => (
                    // @ts-ignore
                    <Page isActive={page === i} onClick={() => setPage(i)} key={i} tabIndex="-1" />
                ))}
            </Pagination>
        </StyledSocialSlider>
    );
};

export { SocialSlider };
