import styled from 'styled-components';

export const StyledInfo = styled.div`
    text-align: center;
    position: relative;

    &:not(:last-child)::before {
        content: '';
        display: block;
        width: 1px;
        height: 40px;
        background: #d9d9d9;
        position: absolute;
        top: 0;
        right: calc(-45px / 2);
        transform: translateY(25%);

        @media screen and (max-width: 1130px) {
            width: 40px;
            height: 1px;
            top: initial;
            bottom: -15px;
            right: 50%;
            transform: translateX(50%);
        }
    }
`;

export const Title = styled.h2`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
`;

export const Description = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 22px;
`;
