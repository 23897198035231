import styled from 'styled-components';

export const StyledInfos = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 45px;

    @media screen and (max-width: 1130px) {
        flex-direction: column;
        grid-gap: 30px;
    }
`;
