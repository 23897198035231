import {
    Container,
    Image,
    Field,
    FieldDescription,
    FieldName,
    Fields,
    Follow,
    SocialSlideFooter,
    StyledSocialSliderItem,
    Username,
    ImageContainer,
    Platform
} from '@components/SocialSlide/SocialSlide.styles';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import Img from 'next/image';

type SocialSliderItemProps = {
    img: string;
    username: string;
    fields: SocialSliderItemField[];
    platform: 'instagram' | 'tiktok';
};

type SocialSliderItemField = {
    name: string;
    description: string;
};

const SocialSlide = ({ img, username, fields, platform }: SocialSliderItemProps) => {
    const { t } = useTranslation();

    const linkToSocial = useMemo(() => {
        if (platform === 'instagram') return `https://www.instagram.com/${username}`;
        if (platform === 'tiktok') return `https://www.tiktok.com/@${username}`;
        return '#';
    }, [platform, username]);

    const btnColor = useMemo(() => {
        if (platform === 'instagram') return `#319cff`;
        if (platform === 'tiktok') return `#fe2c55`;
        return '#fff';
    }, [platform]);

    return (
        <StyledSocialSliderItem>
            <ImageContainer>
                <Image src={img} alt="" layout="fill" />
            </ImageContainer>
            <Container>
                <Platform>
                    <Img src={`/assets/${platform}.svg`} alt="" layout="fill" />
                </Platform>
                <Fields>
                    {fields.map((field) => (
                        <Field key={field.name}>
                            <FieldDescription>{field.description}</FieldDescription>
                            <FieldName>{field.name}</FieldName>
                        </Field>
                    ))}
                </Fields>
                <SocialSlideFooter>
                    <Username>@{username}</Username>
                    <Follow
                        href={linkToSocial}
                        target="_blank"
                        rel="noreferrer"
                        style={{ backgroundColor: btnColor }}
                        id={`socialslider_follow_${platform}`}
                    >{t`socialslider:follow`}</Follow>
                </SocialSlideFooter>
            </Container>
        </StyledSocialSliderItem>
    );
};

export { SocialSlide };
