import styled from 'styled-components';

export const StyledPortfolio = styled.section`
    scroll-margin-top: 102px;
    padding: 88px 0;
    color: #fff;
    position: relative;
`;

export const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #000;
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    filter: grayscale(100%);
    opacity: 0.18;
`;

export const Wrapper = styled.div`
    width: 1067px;
    margin: 0 auto;
    z-index: 100;

    @media screen and (max-width: 1130px) {
        width: 100%;
        padding: 0 20px;
    }
`;

export const Title = styled.h1`
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
`;

export const SubTitle = styled.h2`
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
`;
