import { ReactNode } from 'react';
import { StyledContainer } from './Wrapper.styles';

type ContainerProps = {
    children?: ReactNode;
};

const Wrapper = ({ children }: ContainerProps) => {
    return <StyledContainer>{children}</StyledContainer>;
};

export { Wrapper };
