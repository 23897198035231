import { ReactNode } from 'react';
import { StyledTopList } from '@components/TopList/TopList.styles';

type TopListProps = {
    children: ReactNode;
};

const TopList = ({ children }: TopListProps) => {
    return <StyledTopList>{children}</StyledTopList>;
};

export { TopList };
