import { StyledCarouselItem } from '@components/CarouselItem/CarouselItem.styles';
import { ReactNode } from 'react';

type CarouselItemProps = {
    children?: ReactNode;
    isActive?: boolean;
    onClick?: () => void;
};

const CarouselItem = ({ children, isActive = false, onClick }: CarouselItemProps) => {
    return (
        <StyledCarouselItem isActive={isActive} onClick={onClick}>
            {children}
        </StyledCarouselItem>
    );
};

export { CarouselItem };
