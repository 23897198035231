import { Confetti, StyledForm } from '@components/ContactForm/ContactForm.styles';
import { Formik, FormikHelpers } from 'formik';
import useTranslation from 'next-translate/useTranslation';
import { Field } from '@components/ContactForm/Field/Field';
import { TextAreaField } from '@components/ContactForm/TextAreaField/TextAreaField';
import { Checkbox } from '@components/ContactForm/Checkbox/Checkbox';
import { SubmitButton } from '@components/ContactForm/SubmitButton/SubmitButton';
import * as Yup from 'yup';
import 'yup-phone';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useCallback, useRef, useState } from 'react';
import axios from 'axios';
import canvasConfetti from 'canvas-confetti';

export type ContactFormValues = {
    sender: string;
    email: string;
    phoneNumber: string;
    message: string;
    rodo: boolean;
};

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    sender: Yup.string().required(),
    phoneNumber: Yup.string().phone().required(),
    message: Yup.string().required(),
    rodo: Yup.boolean().required().isTrue()
});

const ContactForm = () => {
    const [token, setToken] = useState<string>('');
    const [step, setStep] = useState<'loading' | 'finish' | 'error' | 'button'>('button');
    const { t } = useTranslation('contactform');
    const confetti = useRef<HTMLCanvasElement>(null);

    const handleSubmit = useCallback(
        async (values: ContactFormValues, helpers: FormikHelpers<any>) => {
            setStep('loading');

            try {
                const request = await axios.post('/api/contact', { ...values, token });
                if (request.status === 200) {
                    setStep('finish');
                    if (confetti.current) {
                        const conf = canvasConfetti.create(confetti.current, {
                            resize: true
                        });
                        conf();
                    }
                } else setStep('error');
            } catch (e) {
                setStep('error');
            }

            helpers.setSubmitting(false);
        },
        [token]
    );

    const handleVerify = useCallback((token: string) => {
        setToken(token);
    }, []);

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GRECAPTCHAKey as string}>
            <Formik
                initialValues={{
                    email: '',
                    sender: '',
                    phoneNumber: '',
                    message: '',
                    rodo: false
                }}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                <StyledForm data-aos="fade">
                    <Confetti ref={confetti} />
                    <Field type="text" name="sender" label={t`fields.name`} required={true} placeholder="RECCLY" />
                    <Field
                        type="text"
                        name="email"
                        label={t`fields.e-mail`}
                        required={true}
                        placeholder="daniel@reccly.pl"
                    />
                    <Field
                        type="text"
                        name="phoneNumber"
                        label={t`fields.phoneNumber`}
                        required={true}
                        placeholder="+48 123 456 789"
                    />
                    <TextAreaField
                        name="message"
                        label={t`fields.messageContent`}
                        required={true}
                        placeholder={t`placeholders.message`}
                    />
                    <Checkbox label={t`fields.rodo`} name="rodo" required={true} />
                    <GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={true} />
                    <SubmitButton value={t`fields.submit`} step={step} />
                </StyledForm>
            </Formik>
        </GoogleReCaptchaProvider>
    );
};

export { ContactForm };
