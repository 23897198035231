import { Partner, StyledPartners } from '@components/Partners/Partners.styles';
import Img from 'next/image';
import { useContext } from 'react';
import { SliderContext } from '../../contexts/SliderContext';

type PartnersProps = {
    partners: (string | null)[];
};

const Partners = ({ partners }: PartnersProps) => {
    // @ts-ignore
    const { currentBrand } = useContext(SliderContext);
    partners = Array.from(new Set(partners));

    return (
        <StyledPartners>
            {partners.map((partner) => (
                <Partner isActive={partner === currentBrand} key={partner}>
                    {partner && <Img src={`/assets/partners/${partner}.png`} alt="" width={200} height={50} />}
                </Partner>
            ))}
        </StyledPartners>
    );
};

export { Partners };
