import styled from 'styled-components';

export const StyledTop = styled.div`
    text-align: center;
    position: relative;

    ${({ isDimmed }: { isDimmed?: boolean }) => isDimmed && `color: #D1D1D1;`}

    &:not(:last-child)::before {
        content: '';
        display: block;
        background: #cfcfcf;
        width: 1px;
        height: 50%;
        position: absolute;
        top: 0;
        right: -40px;
        transform: translateY(50%);
    }
`;

export const Year = styled.p`
    font-weight: 700;
    font-size: 20px;
    transform-origin: center;
    transform: rotate(-5deg);
    margin: 0;
`;

export const Country = styled.p`
    font-weight: 700;
    font-size: 22px;
    margin: 0;
`;

export const TakenPlace = styled.p`
    margin: 0;
    font-weight: 300;
    font-size: 16px;
`;
