import styled from 'styled-components';

export const StyledContact = styled.section`
    scroll-margin-top: 102px;
    padding: 70px 0;
`;

export const Title = styled.h1`
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
`;

export const SubTitle = styled.h2`
    margin: 20px 0 0;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
`;
