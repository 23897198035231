import { Country, StyledTop, TakenPlace, Year } from '@components/Top/Top.styles';
import useTranslation from 'next-translate/useTranslation';

export type TopProps = {
    year: number | string;
    country: string;
    takenPlace: number | string;
    isDimmed?: boolean;
};

const Top = ({ year, country, takenPlace, isDimmed = false }: TopProps) => {
    const { t } = useTranslation('toplist');

    return (
        <StyledTop isDimmed={isDimmed}>
            <Year>{year}</Year>
            <Country>{country}</Country>
            <TakenPlace>{t(`takenPlace`, { takenPlace })}</TakenPlace>
        </StyledTop>
    );
};

export { Top };
