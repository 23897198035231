import styled from 'styled-components';

export const AboutMeSection = styled.section`
    scroll-margin-top: 102px;
    position: relative;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 87px 0 70px;

    @media screen and (max-width: 1130px) {
        grid-template-columns: 1fr;
        grid-gap: 65px;
        padding: 87px 0 0;

        &:last-child {
            padding: 40px 0 0;
        }
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    &.changeOrder {
        @media screen and (max-width: 1130px) {
            order: 2;
        }
    }
`;

export const Description = styled.div`
    text-align: center;
    width: 540px;

    @media screen and (max-width: 580px) {
        width: 100%;
    }
`;

export const Title = styled.h2`
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
`;

export const SubTitle = styled.h2`
    font-weight: 700;
    font-size: 40px;
    margin: 0;
`;

export const StyledParagraph = styled.p`
    font-weight: 400;
    font-size: 16px;
    margin: 15px 0 0;
`;

export const RightContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    &.changeOrder {
        @media screen and (max-width: 1130px) {
            order: 1;
        }
    }
`;

export const YTVideo = styled.iframe`
    width: 529px;
    height: 323px;
    border-radius: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media screen and (max-width: 600px) {
        width: 330px;
        height: 280px;
    }
`;

export const VideoTitle = styled.p`
    font-style: italic;
    font-size: 18px;
`;

export const RoundedSquare = styled.div`
    &::before {
        content: '';
        display: block;
        width: 50%;
        height: 321px;
        background: #000;
        top: -1px;
        right: 0;
        position: absolute;
        z-index: -1;
        border-radius: 0 0 0 50px;

        @media screen and (max-width: 1130px) {
            width: 100%;
        }
    }

    &::after {
        content: '';
        display: block;
        width: 48%;
        height: 321px;
        background: #f5f5f5;
        top: 70px;
        right: 0;
        position: absolute;
        z-index: -10;
        border-radius: 0 0 0 50px;

        @media screen and (max-width: 1130px) {
            width: 98%;
            top: 70px;
        }
    }
`;

export const BottomSquare = styled.div`
    &::before {
        content: '';
        display: block;
        width: 30%;
        height: 275px;
        background: #000;
        bottom: 80px;
        left: 0;
        position: absolute;
        z-index: -1;
        border-radius: 0 50px 50px 0;

        @media screen and (max-width: 1130px) {
            display: none;
        }
    }

    &::after {
        content: '';
        display: block;
        width: 32%;
        height: 275px;
        background: #f5f5f5;
        bottom: 10px;
        left: 0;
        position: absolute;
        z-index: -10;
        border-radius: 0 50px 50px 0;

        @media screen and (max-width: 1130px) {
            display: none;
        }
    }
`;
