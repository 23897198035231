import styled from 'styled-components';

export const StyledPartners = styled.div`
    position: relative;
    height: 75px;

    @media screen and (max-width: 1130px) {
        margin-top: 40px;
    }
`;

export const Partner = styled.div`
    min-width: 200px;
    min-height: 50px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999;
    opacity: 0;
    transition: opacity 250ms;
    & img {
        border-radius: 10px;
    }

    ${({ isActive }: { isActive?: boolean }) => isActive && `opacity: 1;`}
`;
