import styled from 'styled-components';

export const StyledHeader = styled.header`
    width: 100%;
    color: #fff;
    position: fixed;
    padding: 25px 0;
    top: 0;
    left: 0;
    z-index: 9999999;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        transition: transform 250ms;

        ${({ isScroll = false }: { isScroll?: boolean }) => isScroll === true && `transform: translateY(0);`}
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Logo = styled.div`
    width: 130px;
    height: 45px;
    position: relative;
    transition: transform 250ms;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        width: 127.5px;
        height: 44px;
    }

    &:hover {
        transform: scale(1.1);
    }
`;

export const Navbar = styled.nav`
    @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #000;
        z-index: -1;
        transform: translateY(-100%);
        transition: transform 250ms;

        ${({ isHamburgerOpen = false }: { isHamburgerOpen?: boolean }) =>
            isHamburgerOpen && `transform: translateY(0%);`}
    }
`;

export const NavbarList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    grid-gap: 50px;
    font-weight: 500;
    font-size: 20px;

    @media screen and (max-width: 900px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 40px;
        font-size: 20px;
    }
`;

export const NavbarItem = styled.li`
    position: relative;
`;

export const HamburgerIcon = styled.button`
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 30px;
    height: 5px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    position: relative;
    display: none;
    transition: background-color 500ms;

    @media screen and (max-width: 900px) {
        display: block;
    }

    ${({ isHamburgerOpen = false }: { isHamburgerOpen?: boolean }) =>
        isHamburgerOpen && `background: rgba(255, 255, 255, 0);`}

    &::before,
    &::after {
        content: '';
        display: block;
        width: 30px;
        height: 5px;
        border-radius: 2px;
        background: #fff;
        position: absolute;
        opacity: 1;
        transition: transform 500ms;
    }

    &::before {
        top: -10px;
        left: 0;
        ${({ isHamburgerOpen = false }: { isHamburgerOpen?: boolean }) =>
            isHamburgerOpen && `transform: translateY(10px) rotate(-45deg);`}
    }

    &::after {
        bottom: -10px;
        left: 0;
        ${({ isHamburgerOpen = false }: { isHamburgerOpen?: boolean }) =>
            isHamburgerOpen && `transform: translateY(-10px) rotate(45deg);`}
    }
`;

export const ItemLink = styled.a`
    text-decoration: none;
    color: #fff;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0%);
        transform-origin: left;
        transition: transform 250ms;
    }

    &:hover::before {
        transform: scaleX(100%);
    }
`;
