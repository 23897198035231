import styled from 'styled-components';

export const StyledTabs = styled.div`
    margin-top: 20px;
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1130px) {
        flex-direction: column;
        grid-gap: 20px;
    }
`;

export const TabSelect = styled.button`
    border: none;
    outline: none;
    background: transparent;
    color: #7e7e7eff;
    font-weight: 400;
    font-size: 25px;
    cursor: pointer;
    text-transform: uppercase;
    transition: color 250ms;
    position: relative;

    ${({ isActive }: { isActive?: boolean }) =>
        isActive &&
        `
    font-weight: 800;
    color: #fff;
    `};
`;

export const TabWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
`;

export const Container = styled.div`
    display: flex;
    grid-gap: 5px;
    transition: transform 500ms;
    position: relative;
`;
