import {
    Background,
    Bottom,
    BottomContainer,
    Container,
    Description,
    IntroSection,
    Language,
    ScrollTo,
    SelectLanguage,
    Socials,
    StyledLink,
    SubTitle,
    Title
} from './Intro.styles';
import Tiktok from '@assets/tiktok.svg';
import Instagram from '@assets/instagram.svg';
import Arrow from '@assets/arrow.svg';
// @ts-ignore
import Languages from '/i18n';
import Link from 'next/link';
import { Wrapper } from '@components/Wrapper/Wrapper';
import useTranslation from 'next-translate/useTranslation';
import Typed from 'react-typed';
import { memo } from 'react';
import Img from 'next/image';

const Intro = memo(() => {
    const { t } = useTranslation('intro');

    return (
        <IntroSection id="intro">
            <Background>
                <Img
                    src="/assets/intro.webp"
                    alt=""
                    layout="fill"
                    objectFit="cover"
                    objectPosition="top"
                    loading="lazy"
                />
            </Background>
            <Wrapper>
                <Container>
                    <Title data-aos="fade-right">
                        <Typed
                            strings={[
                                '<strong>Cześć &#128075;,</strong> jestem',
                                '<strong>Hi &#128075;,</strong> I am',
                                '<strong>Hola &#128075;,</strong> yo soy',
                                '<strong>Ciao &#128075;</strong> sono',
                                '<strong>Halo &#128075;,</strong> saya'
                            ]}
                            typeSpeed={50}
                            backSpeed={25}
                            backDelay={2000}
                            shuffle
                            loop
                        />
                    </Title>
                    <SubTitle data-aos="fade-right">Ana Sofia Rodriguez</SubTitle>
                    <Description data-aos="fade-right">{t`description`}</Description>
                </Container>
                <Bottom data-aos="fade-up" data-aos-delay="500" data-aos-offset="0">
                    <BottomContainer>
                        <Socials>
                            <StyledLink
                                href="https://www.tiktok.com/@anasofiarn12"
                                target="_blank"
                                rel="noreferrer"
                                id="intro_social_tiktok"
                                aria-label="Tiktok"
                            >
                                <Tiktok />
                            </StyledLink>
                            <StyledLink
                                href="https://www.instagram.com/anasofia_rn"
                                target="_blank"
                                rel="noreferrer"
                                id="intro_social_instagram"
                                aria-label="Instagram"
                            >
                                <Instagram />
                            </StyledLink>
                        </Socials>
                        <SelectLanguage>
                            {Languages.locales.map((language: string) => (
                                <Link href="/" locale={language} key={language} passHref>
                                    <Language id={`intro_language_${language}`}>{language}</Language>
                                </Link>
                            ))}
                        </SelectLanguage>
                    </BottomContainer>
                </Bottom>
            </Wrapper>
            <Link href="/#aboutme" passHref>
                <ScrollTo id="intro_scroll" aria-label={t`scrollTo`}>
                    <Arrow />
                </ScrollTo>
            </Link>
        </IntroSection>
    );
});

Intro.displayName = 'Intro';

export { Intro };
