import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

type SliderContextProps = {
    currentBrand: string | null;
    setCurrentBrand: Dispatch<SetStateAction<string | null>>;
};

type SliderContext = {
    children: ReactNode;
};

export const SliderContext = createContext<SliderContextProps | null>(null);

export const SliderProvider = ({ children }: SliderContext) => {
    const [currentBrand, setCurrentBrand] = useState<string | null>(null);

    return <SliderContext.Provider value={{ currentBrand, setCurrentBrand }}>{children}</SliderContext.Provider>;
};
