import { Container, StyledPartners } from '@sections/Partners/Partners.styles';
import { Wrapper } from '@components/Wrapper/Wrapper';
import Img from 'next/image';

const Partners = () => {
    return (
        <StyledPartners id="partners">
            <Wrapper>
                <Container data-aos="fade-up">
                    <Img src="/assets/brands/kruk.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                    <Img src="/assets/brands/vezzi.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                    <Img src="/assets/brands/gymbeam.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                    <Img src="/assets/brands/traki.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                    <Img src="/assets/brands/UP.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                    <Img src="/assets/brands/egz.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                    <Img src="/assets/brands/okulary.png" alt="" width="300" height="142" style={{ opacity: '0.3' }} />
                </Container>
            </Wrapper>
        </StyledPartners>
    );
};

export { Partners };
