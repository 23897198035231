import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 1440px) {
        margin: 0;
        padding: 0 20px;
        width: 100%;
    }
`;
