import {
    SlidesWrapper,
    Slides,
    Option,
    StyledCarousel,
    Button,
    CarouselContainer,
    StyledArrow
} from '@components/Carousel/Carousel.styles';
import { ReactNode, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CarouselItem } from '@components/CarouselItem/CarouselItem';
import Img from 'next/image';
import { useSwipe } from '../../hooks/useSwipe';
import { SliderContext } from '../../contexts/SliderContext';
import useTranslation from 'next-translate/useTranslation';

type CarouselProps = {
    children?: ReactNode;
    itemsPerPage?: number;
    imageWidth?: number;
    images: string[];
    lazyRoot?: RefObject<any>;
    category?: {
        title: {
            [key: string]: string;
        };
        images: {
            directory: string;
            brand: string;
        }[];
    };
};

const Carousel = ({ itemsPerPage = 3, imageWidth = 250, images, lazyRoot = undefined, category }: CarouselProps) => {
    const { t } = useTranslation('carousel');
    const [slide, setSlide] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const swiper = useSwipe({ ref, left: () => setActiveSlide(-1), right: () => setActiveSlide(1) });
    // @ts-ignore
    const { setCurrentBrand } = useContext(SliderContext);

    const setActiveSlide = useCallback(
        (value: number) => {
            // if (slide + value < 0 || slide + value > images.length - 1) return;
            if (slide + value < 0) return setSlide(images.length - 1);
            if (slide + value > images.length - 1) return setSlide(0);
            setSlide((prevState) => prevState + value);
        },
        [slide, images]
    );

    const [width, setWidth] = useState(imageWidth);
    useEffect(() => {
        const onResize = () => {
            if (ref.current) {
                setWidth(
                    ref.current.getBoundingClientRect().width / 2 -
                        imageWidth / 2 +
                        (0 - slide * imageWidth - slide * 5) +
                        (swiper >= 300 ? -300 : swiper <= -300 ? 300 : -swiper)
                );
            }
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [imageWidth, itemsPerPage, slide, swiper]);

    useEffect(() => {
        if (category === null) return;
        setCurrentBrand(category?.images[slide].brand);
    }, [slide, setCurrentBrand, category]);

    return (
        <StyledCarousel>
            <CarouselContainer>
                <Option>
                    <Button onClick={() => setActiveSlide(-1)} aria-label={t`previousSlide`}>
                        <StyledArrow />
                    </Button>
                </Option>
                <SlidesWrapper ref={ref}>
                    <Slides
                        style={{
                            transform: `translateX(${width}px)`,
                            transition: `transform ${swiper === 0 ? `500ms` : '100ms'}`
                        }}
                    >
                        {images.map((image, i) => (
                            <CarouselItem isActive={slide === i} onClick={() => setSlide(i)} key={image}>
                                <Img
                                    src={image}
                                    alt=""
                                    width={250}
                                    height={375}
                                    quality={100}
                                    style={{ borderRadius: '20px' }}
                                    loading="lazy"
                                    lazyRoot={lazyRoot}
                                />
                            </CarouselItem>
                        ))}
                    </Slides>
                </SlidesWrapper>
                <Option>
                    <Button onClick={() => setActiveSlide(1)} aria-label={t`nextSlide`}>
                        <StyledArrow style={{ transform: 'rotate(-90deg)' }} />
                    </Button>
                </Option>
            </CarouselContainer>
        </StyledCarousel>
    );
};

export { Carousel };
