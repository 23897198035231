import styled from 'styled-components';

export const StyledPartners = styled.section`
    scroll-margin-top: 102px;
    padding: 40px 0 50px;
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;
    opacity: 0.3;

    @media screen and (max-width: 1130px) {
        flex-direction: column;
        grid-gap: 30px;
    }
`;
