import { StyledTab } from '@components/Tab/Tab.styles';
import { ReactNode } from 'react';

export type TabProps = {
    title: string;
    children?: ReactNode;
};

const Tab = ({ children }: TabProps) => {
    return <StyledTab>{children}</StyledTab>;
};

export { Tab };
