import { Description, StyledInfo, Title } from '@components/Info/Info.styles';
import { ReactNode } from 'react';

type InfoProps = {
    children: ReactNode;
    title: string;
};

const Info = ({ children, title }: InfoProps) => {
    return (
        <StyledInfo>
            <Title>{title}</Title>
            <Description>{children}</Description>
        </StyledInfo>
    );
};

export { Info };
