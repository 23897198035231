import {
    Container,
    Loading,
    StyledSubmitButton,
    Submit,
    Text
} from '@components/ContactForm/SubmitButton/SubmitButton.styles';
import useTranslation from 'next-translate/useTranslation';

export type SubmitButtonProps = {
    value: string;
    step: 'loading' | 'finish' | 'error' | 'button';
};

const SubmitButton = ({ value, step }: SubmitButtonProps) => {
    const { t } = useTranslation('contactform');

    return (
        <StyledSubmitButton>
            <Container isSent={step === 'finish'}>
                {step === 'button' && <Submit id="contact_submit" type="submit" value={value} />}
                {step === 'loading' && <Loading />}
                {step === 'finish' && <Text>{t`fields.sent`}</Text>}
                {step === 'error' && <Text>Error</Text>}
            </Container>
        </StyledSubmitButton>
    );
};

export { SubmitButton };
