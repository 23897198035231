
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { NextPage } from 'next';
import { Layout } from '@components/Layout';
import { Intro } from '@sections/Intro/Intro';
import { AboutMe } from '@sections/AboutMe/AboutMe';
import { Information } from '@sections/Information/Information';
import { Achievements } from '@sections/Achievements/Achievements';
import { Contact } from '@sections/Contact/Contact';
import { Partners } from '@sections/Partners/Partners';
import { Portfolio } from '@sections/Portfolio/Portfolio';

const Home: NextPage = () => {
    return (
        <Layout>
            <Intro />
            <AboutMe />
            <Information />
            <Partners />
            <Portfolio />
            <Achievements />
            <Contact />
        </Layout>
    );
};

export default Home;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  