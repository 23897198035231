import { Container, Header, StyledTabs, TabSelect, TabWrapper } from '@components/Tabs/Tabs.styles';
import { Children, useEffect, useMemo, useRef, useState } from 'react';

type TabsProps = {
    children: JSX.Element[];
};

const Tabs = ({ children }: TabsProps) => {
    const Categories = useMemo(() => {
        return Children.map(children, (child) => {
            return child.props.title;
        });
    }, [children]);
    const ref = useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const onResize = () => {
            if (ref.current) setWidth(ref.current.getBoundingClientRect().width);
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <StyledTabs ref={ref} data-aos="fade-down">
            <Header>
                {Categories.map((category, i) => (
                    <TabSelect onClick={() => setTab(i)} isActive={i === tab} key={category}>
                        {category}
                    </TabSelect>
                ))}
            </Header>
            <TabWrapper>
                <Container style={{ transform: `translateX(${-width * tab - tab * 5}px)` }} data-aos="fade">
                    {children}
                </Container>
            </TabWrapper>
        </StyledTabs>
    );
};

export { Tabs };
