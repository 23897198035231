import styled from 'styled-components';

export const StyledCheckbox = styled.label`
    display: flex;
    align-items: flex-start;
    grid-column: 1 / span 3;
    grid-gap: 5px;
    cursor: pointer;
`;

export const Input = styled.input`
    appearance: none;
    outline: none;
    cursor: pointer;
    background: #000000;
    border-radius: 10px;
    min-width: 15px;
    min-height: 15px;
    position: relative;
    border: 4px solid #000;
    transition: background-color 250ms, box-shadow 250ms;

    &:checked {
        background: #fff;
    }

    ${({ isError }: { isError?: boolean }) => isError && `box-shadow: 0 0 10px 3px #ff0000;`}
`;

export const Title = styled.p`
    font-weight: 400;
    font-size: 12px;
    padding: 0;
    margin: 0;

    ${({ required }: { required?: boolean }) =>
        required &&
        `
        &::before {
            content: '* ';
            color: #FF001F;
        }
    `}
`;
