import { RefObject, useEffect, useRef, useState } from 'react';

type useSwipeProps = {
    ref: RefObject<HTMLElement>;
    left?: () => void;
    right?: () => void;
    up?: () => void;
    down?: () => void;
};

const useSwipe = ({ ref, left, right, up, down }: useSwipeProps) => {
    const touchCoordsRef = useRef({ touchStart: { x: 0, y: 0, time: Date.now() } });
    const fnsRef = useRef({ up, down, left, right });
    const [width, setWidth] = useState(0);

    fnsRef.current = {
        up,
        left,
        down,
        right
    };

    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            touchCoordsRef.current.touchStart.x = e.targetTouches[0].clientX;
            touchCoordsRef.current.touchStart.y = e.targetTouches[0].clientY;
            touchCoordsRef.current.touchStart.time = Date.now();
        };

        const handleTouchMove = (e: TouchEvent) => {
            const xDistance = touchCoordsRef.current.touchStart.x - e.targetTouches[0].clientX;
            setWidth(xDistance);
        };

        const handleTouchEnd = (e: TouchEvent) => {
            setWidth(0);
            const threshold = 50;
            const swipeSpeed = 1;
            const touchEndX = e.changedTouches[0].clientX;
            const touchEndY = e.changedTouches[0].clientY;
            const touchStartX = touchCoordsRef.current.touchStart.x;
            const touchStartY = touchCoordsRef.current.touchStart.y;
            const elapsedTime = (Date.now() - touchCoordsRef.current.touchStart.time) / 1000;

            if (elapsedTime > swipeSpeed) return;

            const xDistance = touchStartX - touchEndX;
            const yDistance = touchStartY - touchEndY;

            if (Math.abs(xDistance) < threshold && Math.abs(yDistance) < threshold) return;

            if (Math.abs(xDistance) >= Math.abs(yDistance)) {
                xDistance > 0 ? fnsRef.current.right?.() : fnsRef.current.left?.();
            } else {
                yDistance > 0 ? fnsRef.current.down?.() : fnsRef.current.up?.();
            }
        };

        if (ref.current) {
            ref.current.addEventListener('touchstart', handleTouchStart, { passive: true });
            ref.current.addEventListener('touchmove', handleTouchMove, { passive: true });
            ref.current.addEventListener('touchend', handleTouchEnd, { passive: true });
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('touchstart', handleTouchStart);
                ref.current.removeEventListener('touchmove', handleTouchMove);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                ref.current.removeEventListener('touchend', handleTouchEnd);
            }
        };
    });

    return width;
};

export { useSwipe };
