import styled, { keyframes } from 'styled-components';

export const StyledSubmitButton = styled.div`
    display: flex;
    justify-content: center;
    grid-column: 1 / span 3;
    z-index: 99999999999;
`;

export const Container = styled.label`
    width: 185px;
    height: 58px;
    padding: 14px 64px;
    background-color: #000000;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background-color 500ms;

    ${({ isSent }: { isSent?: boolean }) => isSent && `background-color: #CACACA;cursor: no-drop;`}

    .fade-enter {
        opacity: 0;
    }
    .fade-exit {
        opacity: 1;
    }
    .fade-enter-active {
        opacity: 1;
    }
    .fade-exit-active {
        opacity: 0;
    }
    .fade-enter-active,
    .fade-exit-active {
        transition: opacity 500ms;
    }
`;

export const Submit = styled.input`
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    background-color: transparent;
    transition: transform 500ms;

    ${({ isActive = true }: { isActive?: boolean }) => !isActive && `transform: translateX(500%);`}
`;

export const LoadingAnimation = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Loading = styled.span`
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${LoadingAnimation} 1000ms 200ms linear infinite;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 15px;
        position: absolute;
    }

    &::before {
        top: 0;
        left: -20px;
        animation: ${LoadingAnimation} 1000ms 0ms linear infinite;
    }

    &::after {
        top: 0;
        right: -20px;
        animation: ${LoadingAnimation} 1000ms 400ms linear infinite;
    }
`;

export const Text = styled.p`
    appearance: none;
    margin: 0;
    padding: 0;
    color: #afafaf;
`;
