import styled from 'styled-components';
import { Form } from 'formik';

export const StyledForm = styled(Form)`
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px 55px;
    position: relative;

    @media screen and (max-width: 1130px) {
        display: flex;
        flex-direction: column;
    }
`;

export const Confetti = styled.canvas`
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
    z-index: 99999;
`;
