import {
    AboutMeSection,
    BottomSquare,
    Description,
    Grid,
    LeftContainer,
    RightContainer,
    RoundedSquare,
    StyledParagraph,
    SubTitle,
    Title,
    VideoTitle,
    YTVideo
} from './AboutMe.styles';
import { Wrapper } from '@components/Wrapper/Wrapper';
import { SocialSlider } from '@components/SocialSlider/SocialSlider';
import { SocialSlide } from '@components/SocialSlide/SocialSlide';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { useRef } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

const AboutMe = () => {
    const { t } = useTranslation();
    const root = useRef<HTMLDivElement>(null);
    const entry = useIntersectionObserver(root, { threshold: 0.1 });
    const isVisible = !!entry?.isIntersecting;

    return (
        <AboutMeSection id="aboutme" ref={root}>
            <RoundedSquare />
            <BottomSquare />
            <Wrapper>
                <Grid>
                    <LeftContainer className="changeOrder">
                        <Description data-aos="fade-right">
                            <Title>{t`aboutme:about-me.title`}</Title>
                            <SubTitle>{t`aboutme:about-me.subTitle`}</SubTitle>
                            <StyledParagraph>
                                <Trans
                                    i18nKey="aboutme:about-me.description"
                                    components={{
                                        strong: <strong />
                                    }}
                                />
                            </StyledParagraph>
                        </Description>
                    </LeftContainer>
                    <RightContainer className="changeOrder">
                        {isVisible && (
                            <YTVideo
                                src="https://www.youtube.com/embed/PZl_9J46c8g?controls=1&modestbranding=0&rel=0&start=0"
                                title="YouTube video player"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                                loading="lazy"
                                allowFullScreen
                            ></YTVideo>
                        )}
                        <VideoTitle>Malportaos - Sola (Video Oficial)</VideoTitle>
                    </RightContainer>
                </Grid>
                <Grid>
                    <LeftContainer data-aos="fade-right">
                        <SocialSlider>
                            <SocialSlide
                                platform="tiktok"
                                fields={[
                                    { description: '1.1k', name: t`socialslider:followers` },
                                    { description: '11k', name: t`socialslider:likes` },
                                    { description: '98', name: t`socialslider:following` }
                                ]}
                                username="anasofiarn12"
                                img="/assets/profile.jpg"
                            />
                            <SocialSlide
                                platform="instagram"
                                fields={[
                                    { description: '81.6k', name: t`socialslider:followers` },
                                    { description: '253', name: t`socialslider:posts` },
                                    { description: '1 884', name: t`socialslider:following` }
                                ]}
                                username="anasofia_rn"
                                img="/assets/profile.jpg"
                            />
                        </SocialSlider>
                    </LeftContainer>
                    <RightContainer data-aos="fade-left">
                        <Description>
                            <Title>{t`aboutme:curiosities.title`}</Title>
                            <SubTitle>{t`aboutme:curiosities.subTitle`}</SubTitle>
                            <StyledParagraph>
                                <Trans
                                    i18nKey="aboutme:curiosities.description"
                                    components={{
                                        strong: <strong />
                                    }}
                                />
                            </StyledParagraph>
                        </Description>
                    </RightContainer>
                </Grid>
            </Wrapper>
        </AboutMeSection>
    );
};

export { AboutMe };
