import styled from 'styled-components';
import Arrow from '@assets/arrow.svg';

export const StyledCarousel = styled.div`
    width: 100%;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    padding: 50px 55px;
    position: relative;

    @media screen and (max-width: 600px) {
        padding: 50px 20px;
    }
`;

export const CarouselContainer = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    gap: 50px;

    @media screen and (max-width: 1130px) {
        grid-template-columns: 1fr;
        gap: 0;
        justify-items: center;
    }
`;

export const SlidesWrapper = styled.div`
    padding: 25px 0;
    display: flex;
    overflow: hidden;
    align-items: center;
    width: calc(100% + 25px);

    @media screen and (max-width: 1130px) {
        width: calc(100%);
    }
`;

export const Slides = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    transition: transform 500ms ease-in;
`;

export const Option = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: relative;

    @media screen and (max-width: 1130px) {
        bottom: 50%;
        &:first-child {
            position: absolute;
            left: 3px;
        }

        &:last-child {
            position: absolute;
            right: 3px;
        }
    }
`;

export const Button = styled.button`
    outline: none;
    border: none;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 250ms ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

export const StyledArrow = styled(Arrow)`
    fill: #080808;
    transform-origin: center;
    transform: rotate(90deg);
`;
