import styled from 'styled-components';

export const StyledAchievements = styled.section`
    scroll-margin-top: 102px;
    background-color: #f5f5f5;
    position: relative;

    @media screen and (max-width: 600px) {
        padding: 50px 0 0;
    }
`;

export const ImageContainer = styled.div`
    width: 50%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: -1px;
    right: 0;
    filter: grayscale(100%);

    @media screen and (max-width: 1130px) {
        position: relative;
        width: 100%;
        height: 800px;
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: -1px;
        right: 0;
        filter: grayscale(100%);
        z-index: 100;

        @media screen and (max-width: 1130px) {
            top: initial;
            right: initial;
            position: relative;
        }
    }
`;

export const Achievement = styled.div`
    min-height: 612px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    position: relative;

    @media screen and (max-width: 1130px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    @media screen and (max-width: 600px) {
        padding: 0 20px;
    }
`;

export const Logo = styled.div`
    width: 97px;
    height: 89px;
    background: linear-gradient(180deg, #161616 31.77%, #333333 100%);
    border-radius: 15px;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: rotate(-9deg) translate(-50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    @media screen and (max-width: 1130px) {
        bottom: -80px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-9deg);
    }
`;

export const Container = styled.div`
    width: 528px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;

    @media screen and (max-width: 1130px) {
        justify-content: center;
        padding-bottom: 80px;
    }
`;

export const Title = styled.h1`
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
`;

export const SubTitle = styled.h2`
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
`;

export const Description = styled.p`
    margin: 0 0 15px;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
`;
