import { StyledContact, SubTitle, Title } from './Contact.styles';
import { Wrapper } from '@components/Wrapper/Wrapper';
import { ContactForm } from '@components/ContactForm/ContactForm';
import Trans from 'next-translate/Trans';
import { memo } from 'react';

const Contact = memo(() => {
    return (
        <StyledContact id="contact">
            <Wrapper>
                <Title data-aos="fade-down">
                    <Trans
                        i18nKey="contact:title"
                        components={{
                            br: <br />
                        }}
                    />
                </Title>
                <SubTitle data-aos="fade-down">
                    <Trans
                        i18nKey="contact:subTitle"
                        components={{
                            br: <br />
                        }}
                    />
                </SubTitle>
                <ContactForm />
            </Wrapper>
        </StyledContact>
    );
});

Contact.displayName = 'Contact';

export { Contact };
