import {
    Container,
    HamburgerIcon,
    ItemLink,
    Logo,
    Navbar,
    NavbarItem,
    NavbarList,
    StyledHeader
} from '@components/Header/Header.styles';
import { Wrapper } from '@components/Wrapper/Wrapper';
import Img from 'next/image';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

const Header = () => {
    const { t } = useTranslation('navbar');
    const [isScroll, setIsScroll] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isHamburger, setIsHamburger] = useState<boolean>(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) return setIsScroll(true);
            setIsScroll(false);
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth > 900) {
                setIsOpen(false);
                setIsHamburger(false);
            } else {
                setIsHamburger(true);
            }
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    });

    const closeHamburger = useCallback(() => {
        if (isHamburger) setIsOpen(false);
    }, [isHamburger]);

    return (
        <StyledHeader isScroll={isScroll}>
            <Wrapper>
                <Container>
                    <Logo>
                        <Img src="/assets/reccly_dev.svg" alt="" layout="fill" />
                    </Logo>
                    <HamburgerIcon
                        isHamburgerOpen={isOpen}
                        onClick={() => setIsOpen((prevState) => !prevState)}
                        aria-label="Hamburger menu"
                    />
                    <Navbar
                        isHamburgerOpen={isOpen}
                        style={{ transition: isHamburger ? 'transform 500ms' : 'transform 0ms' }}
                    >
                        <NavbarList>
                            <NavbarItem>
                                <Link href="/#intro" passHref>
                                    <ItemLink onClick={closeHamburger}>{t`intro`}</ItemLink>
                                </Link>
                            </NavbarItem>
                            <NavbarItem>
                                <Link href="/#aboutme" passHref>
                                    <ItemLink onClick={closeHamburger}>{t`about-me`}</ItemLink>
                                </Link>
                            </NavbarItem>
                            <NavbarItem>
                                <Link href="/#portfolio" passHref>
                                    <ItemLink onClick={closeHamburger}>{t`portfolio`}</ItemLink>
                                </Link>
                            </NavbarItem>
                            <NavbarItem>
                                <Link href="/#contact" passHref>
                                    <ItemLink onClick={closeHamburger}>{t`collaboration`}</ItemLink>
                                </Link>
                            </NavbarItem>
                            <NavbarItem>
                                <Link href="/#contact" passHref>
                                    <ItemLink onClick={closeHamburger}>{t`contact`}</ItemLink>
                                </Link>
                            </NavbarItem>
                        </NavbarList>
                    </Navbar>
                </Container>
            </Wrapper>
        </StyledHeader>
    );
};

export { Header };
