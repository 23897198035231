import styled from 'styled-components';
import Img from 'next/image';

export const StyledSocialSliderItem = styled.div`
    min-width: calc(100% - 4px);
    height: 157px;
    display: flex;
    align-items: center;
    position: relative;
`;

export const Container = styled.div`
    width: 100%;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 15px 44px 15px 90px;
    display: grid;
    grid-template-rows: auto 25px;
    grid-gap: 10px;
    position: relative;

    @media screen and (max-width: 540px) {
        width: 275px;
        padding: 10px 20px 10px 60px;
        grid-template-rows: auto 20px;
        border-radius: 15px;
    }
`;

export const ImageContainer = styled.div`
    min-width: 155px;
    min-height: 155px;
    margin-right: -70px;
    z-index: 10;
    position: relative;

    @media screen and (max-width: 540px) {
        min-width: 90px;
        min-height: 100px;
        margin-right: -50px;
    }
`;

export const Image = styled(Img)`
    border-radius: 40px;

    @media screen and (max-width: 540px) {
        border-radius: 20px;
    }
`;

export const Platform = styled.div`
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    width: 20px;
    height: 20px;

    & > img {
        filter: grayscale(100%);
    }

    @media screen and (max-width: 540px) {
        width: 15px;
        height: 15px;
        top: 10px;
        right: 10px;
    }
`;

export const Fields = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
`;

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FieldDescription = styled.p`
    margin: 0;
    padding: 0;
    color: #080808;
    font-weight: 700;
    font-size: 30px;

    @media screen and (max-width: 540px) {
        font-size: 16px;
    }
`;

export const FieldName = styled.p`
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;

    @media screen and (max-width: 540px) {
        font-size: 10px;
    }
`;

export const SocialSlideFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

export const Username = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 14px;

    @media screen and (max-width: 540px) {
        font-size: 10px;
    }
`;

export const Follow = styled.a`
    text-decoration: none;
    width: 35%;
    height: 100%;
    border: none;
    background: #319cff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 540px) {
        font-size: 10px;
    }
`;
