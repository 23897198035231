import styled from 'styled-components';

export const StyledFooter = styled.footer`
    background: #080808;
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 63px 0;

    @media screen and (max-width: 1130px) {
        grid-gap: 30px;
        padding: 0 0 30px;
    }
`;

export const BackgroundImg = styled.div`
    width: calc(50% - 15px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/assets/footer.webp');
    background-size: cover;
    filter: grayscale(100%);
    opacity: 0.15;

    @media screen and (max-width: 1130px) {
        position: relative;
        height: 300px;
        width: 100%;
        background-position: top center;
    }
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 15px;

    @media screen and (max-width: 1130px) {
        grid-template-columns: 1fr;
        grid-gap: 30px;
        padding-bottom: 50px;
    }
`;

export const RightContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 1130px) {
        grid-column-start: 1;
    }
`;

export const Header = styled.h1`
    font-weight: 700;
    font-size: 40px;
    margin: 0;
    word-wrap: anywhere;

    @media screen and (max-width: 1130px) {
        text-align: center;
    }

    @media screen and (max-width: 600px) {
        font-size: 22px;
    }
`;

export const SocialMedias = styled.div`
    display: flex;
    grid-gap: 70px;
    margin-top: 30px;

    @media screen and (max-width: 1130px) {
        justify-content: center;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 15px 0;
    }
`;

export const SocialMedia = styled.div`
    width: 110px;
`;

export const Title = styled.h2`
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    color: #313131;

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
`;

export const Username = styled.p`
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #ffffff;

    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;

export const Link = styled.a`
    text-decoration: none;
    color: #fff;
    font-weight: 500;
`;

export const CreatorLink = styled(Link)`
    transition: color 250ms;

    &:hover {
        color: #1fa9ff;
    }
`;

export const Center = styled.div`
    margin-top: 37px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Tags = styled.p`
    margin: 0;
    width: 416px;
    text-align: center;
    color: #1b1b1b;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        width: 100%;
    }
`;

export const CreatorContainer = styled.div`
    width: 100%;
    margin-top: 30px;
`;

export const Creator = styled.p`
    margin: 0;
    text-align: center;
    color: #1d1d1d;

    & > strong {
        font-weight: 500;
        transition: color 250ms;
    }
`;
