import styled from 'styled-components';

export const StyledField = styled.label``;

export const Title = styled.p`
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 10px;

    ${({ required }: { required?: boolean }) =>
        required &&
        `
        &::after {
            content: ' *';
            color: #FF001F;
        }
    `}
`;

export const Input = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    width: 100%;
    color: #fff;
    background: #080808;
    border-radius: 15px;
    padding: 25px 17px;
    font-weight: 600;
    font-size: 18px;
    transition: box-shadow 250ms;

    ${({ isError }: { isError?: boolean }) => isError && `box-shadow: 0 0 10px 3px #ff0000;`}
`;
