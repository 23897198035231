import { StyledInformation } from './Information.styles';
import { Wrapper } from '@components/Wrapper/Wrapper';
import { Infos } from '@components/Infos/Infos';
import { Info } from '@components/Info/Info';
import { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

type InformationProps = {
    title: string;
    description: string;
};

const Information = () => {
    const { t } = useTranslation('information');

    const age = useMemo(() => {
        const now = new Date();
        const dateOfBirth = new Date(1998, 2, 12);
        const timeDiff = new Date(now.getTime() - dateOfBirth.getTime());
        return Math.abs(timeDiff.getUTCFullYear() - 1970);
    }, []);

    const information = t('information', { age }, { returnObjects: true }) as Array<InformationProps>;

    return (
        <StyledInformation id="information" data-aos="fade-up">
            <Wrapper>
                <Infos>
                    {information.map(({ title, description }) => (
                        <Info title={title} key={title}>
                            {description}
                        </Info>
                    ))}
                </Infos>
            </Wrapper>
        </StyledInformation>
    );
};

export { Information };
