import { StyledPortfolio, SubTitle, Title, Video, VideoContainer, Wrapper } from '@sections/Portfolio/Portfolio.styles';
import { Tabs } from '@components/Tabs/Tabs';
import { Tab } from '@components/Tab/Tab';
import { Carousel } from '@components/Carousel/Carousel';
import useTranslation from 'next-translate/useTranslation';
import { memo, useRef } from 'react';
import { SliderProvider } from '../../contexts/SliderContext';
import { Partners } from '@components/Partners/Partners';
import { useIntersectionObserver } from 'usehooks-ts';

export const ListSlide = [
    {
        title: {
            en: 'Fashion',
            pl: 'Moda'
        },
        images: [
            {
                directory: '/moda/1.jpg',
                brand: 'okulary'
            },
            {
                directory: '/moda/2.JPG',
                brand: 'okulary'
            },
            {
                directory: '/moda/3.JPG',
                brand: 'okulary'
            },
            {
                directory: '/moda/4.JPG',
                brand: 'okulary'
            },
            {
                directory: '/moda/5.jpg',
                brand: 'okulary'
            },
            {
                directory: '/moda/6.JPG',
                brand: 'okulary'
            },
            {
                directory: '/moda/7.JPG',
                brand: 'okulary'
            },
            {
                directory: '/moda/8.jpg',
                brand: 'vezzi'
            },
            {
                directory: '/moda/9.jpg',
                brand: 'vezzi'
            },
            {
                directory: '/moda/10.jpg',
                brand: 'vezzi'
            },
            {
                directory: '/moda/11.jpg',
                brand: 'vezzi'
            }
        ]
    },
    {
        title: {
            en: 'Sport',
            pl: 'Sport'
        },
        images: [
            {
                directory: '/sport/1.jpg',
                brand: 'gymbeam'
            },
            {
                directory: '/sport/2.jpg',
                brand: 'gymbeam'
            },
            {
                directory: '/sport/3.jpg',
                brand: 'gymbeam'
            },
            {
                directory: '/sport/4.jpg',
                brand: 'gymbeam'
            },
            {
                directory: '/sport/5.jpg',
                brand: 'gymbeam'
            },
            {
                directory: '/sport/6.jpg',
                brand: 'gymbeam'
            },
            {
                directory: '/sport/7.jpg',
                brand: 'gymbeam'
            }
        ]
    },
    {
        title: {
            en: 'Photography',
            pl: 'Fotografia'
        },
        images: [
            {
                directory: '/fotografia/1.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/2.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/3.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/4.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/5.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/6.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/7.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/8.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/9.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/10.png',
                brand: 'reccly'
            },
            {
                directory: '/fotografia/11.jpg',
                brand: 'reccly'
            }
        ]
    },
    {
        title: {
            en: 'Miss Egzotica 2022 👑',
            pl: 'Miss Egzotica 2022 👑'
        },
        images: [
            {
                directory: '/miss_egzotica/1.jpeg',
                brand: 'egz'
            },
            {
                directory: '/miss_egzotica/2.jpeg',
                brand: 'egz'
            },
            {
                directory: '/miss_egzotica/3.jpeg',
                brand: 'egz'
            }
        ]
    }
];

const Portfolio = memo(() => {
    const video = useRef<HTMLVideoElement>(null);
    const { t, lang } = useTranslation('portfolio');

    const root = useRef<HTMLDivElement>(null);
    const entry = useIntersectionObserver(root, {});
    const isVisible = !!entry?.isIntersecting;

    // @ts-ignore
    return (
        <StyledPortfolio id="portfolio" ref={root}>
            <VideoContainer>
                <Video autoPlay loop muted ref={video}>
                    {isVisible && <source src="/assets/background.webm" type="video/webm" />}
                </Video>
            </VideoContainer>
            <Wrapper>
                <Title data-aos="fade-down">{t`title`}</Title>
                <SubTitle data-aos="fade-down">{t`subTitle`}</SubTitle>
                <Tabs>
                    {ListSlide.map((category, i) => (
                        /* @ts-ignore */
                        <Tab title={category.title[lang] || category.title[0]} key={i}>
                            <SliderProvider>
                                <Carousel
                                    images={category.images.map((img) => `/assets/sliders${img.directory}`)}
                                    category={category}
                                ></Carousel>
                                <Partners
                                    partners={category.images.reduce<string[]>((acc, image) => {
                                        acc.push(image.brand);
                                        return acc;
                                    }, [])}
                                ></Partners>
                            </SliderProvider>
                        </Tab>
                    ))}
                </Tabs>
            </Wrapper>
        </StyledPortfolio>
    );
});

Portfolio.displayName = 'Portfolio';

export { Portfolio };
